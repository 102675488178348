import { SVGProps } from "react";

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112.196 112.196"
    {...props}
  >
    <circle cx={56.098} cy={56.097} r={56.098} />
    <path
      d="M79.124 22.506h-46.05c-5.83 0-10.56 4.73-10.56 10.57v46.05c0 5.83 4.73 10.56 10.56 10.56h46.05c5.83 0 10.56-4.73 10.56-10.56v-46.05c0-5.84-4.73-10.57-10.56-10.57zm-7.141 7.77 8.44-.02h.02c.81-.01 1.47.65 1.47 1.45v8.47c0 .81-.65 1.46-1.46 1.46l-8.43.03c-.81 0-1.46-.65-1.46-1.45l-.03-8.47c-.01-.81.64-1.46 1.45-1.47zm-26.179 20.05c.22-.38.45-.76.71-1.12 2.15-2.98 5.64-4.93 9.58-4.93 3.95 0 7.44 1.95 9.59 4.93.26.36.49.74.71 1.13.96 1.7 1.53 3.67 1.53 5.76 0 6.52-5.31 11.82-11.83 11.82s-11.82-5.3-11.82-11.82c-.001-2.09.56-4.06 1.53-5.77zm37.34 26.38a6.45 6.45 0 0 1-6.44 6.44h-41.21a6.45 6.45 0 0 1-6.44-6.44v-27.5h10.03c-.25.61-.47 1.25-.65 1.89-.46 1.59-.7 3.27-.7 5 0 10.13 8.24 18.37 18.36 18.37 10.13 0 18.37-8.24 18.37-18.37 0-1.73-.25-3.41-.7-5-.19-.64-.4-1.28-.65-1.89h10.03v27.5z"
      fill="#fff"
    />
  </svg>
);

export default InstagramIcon;
