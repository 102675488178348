import React, { useState, useEffect } from "react";
import { useLocation, useNavigate  } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Stack,
  StackProps
} from "@mui/material";

interface SiteLinkProps {
  children: React.ReactNode,
  visible: boolean
  url: string
  top: boolean
}

const SiteLink = ({ children, url, visible, top = true }: SiteLinkProps) => {
  const [active, setActive] = useState<boolean>(false);

  const MenuItem = styled(Stack)<StackProps>(({ theme }) => ({
    height: "100%"
  }));
  
  const LinkStack = styled(Stack)<StackProps>(({ theme }) => ({
    height: "100%",
    paddingTop: (!top ? "15px" : "0"),
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: (top ? "15px" : "0"),
    '&:hover': {
      background: "rgb(150, 153, 155, .15)",
      cursor: "pointer",
      borderTopRightRadius: (!top ? "10px" : "0"),
      borderTopLeftRadius: (!top ? "10px" : "0"),
      borderBottomRightRadius: (top ? "10px" : "0"),
      borderBottomLeftRadius: (top ? "10px" : "0"),
    }
  }));

  const navigation = useNavigate()
  let location = useLocation()

  useEffect(() => {
    if (location.pathname === url) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [url, location])

  const handleClick = () => {
    navigation(url)
  }

  if (!visible) {
    return <></>
  }

  return (
    <MenuItem
      direction="column"
      justifyContent="center"
      alignItems="center"
      onClick={handleClick}
    >
      <LinkStack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        {top &&
          <div style={{
            background: active ? '#f77532' : '',
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            height: "5px",
            width: "35px",
            marginBottom: "20px"
          }}></div>
        }
        {children}
        {!top &&
          <div style={{
            background: active ? '#f77532' : '',
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            height: "5px",
            width: "35px",
            marginTop: "10px"
          }}></div>
        }
      </LinkStack>
    </MenuItem>
  )
};

export default SiteLink;