import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  StackProps,
} from "@mui/material";

import LotteColourIcon from "../../img/lottecolour_icon.png"

interface LogoProps extends StackProps {}

const Logo = ({ ...props }: LogoProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={0}
      {...props}
    >
      <Box>
        <img
          width="120px"
          src={LotteColourIcon}
        />
      </Box>
    </Stack>
  )
};

export default Logo;