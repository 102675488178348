import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Stack,
  StackProps,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
  useMediaQuery
} from "@mui/material";

import { Authenticator } from "@lotte-colour/auth"

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EtsyIcon from "./icons/EtsyIcon";
import InstagramIcon from "./icons/InstagramIcon";

import Logo from './components/logo.component'
import ExternalLinks from './components/external_links.component'
import SiteLink from './components/site_link.component'

import { SHOP_URL, INSTAGRAM_URL } from "./constants/environment";

const Nav: React.FC<{}> = () => {
  const mobileBreakpoint = useMediaQuery('(max-width:774.9px)');
  const desktopBreakpoint = useMediaQuery('(min-width:775px)');

  const PaperRoot = styled(Paper)<PaperProps>(({ theme }) => ({
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.only("xs")]: {
      borderRadius: 0
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: "15px",
      boxShadow: "rgb(255 161 114) 0px 1px 80px 1px",
    },
  }))
  
  const MobileNavigationStack = styled(Stack)<StackProps>(({}) => ({
    height: "100%"
  }))
  
  const DesktopNavigationStack = styled(Stack)<StackProps>(({}) => ({
    height: "100%",
    paddingBottom: "10px"
  }))
  
  const LinkTypography = styled(Typography)<TypographyProps>(({}) => ({
    fontSize: "14px",
    fontWeight: "900"
  }))

  const navigationLinks = (top: boolean) => {
    return (
      <>
        <SiteLink
          visible={true}
          url="/"
          top={top}
        >
          <LinkTypography>Store</LinkTypography>
        </SiteLink>
        <SiteLink
          visible={Authenticator.isLoggedIn()}
          url="/manager"
          top={top}
        >
          <LinkTypography>Manager</LinkTypography>
        </SiteLink>
        <SiteLink
          visible={false}
          url="/gallery"
          top={top}
        >
          <LinkTypography>Gallery</LinkTypography>
        </SiteLink>
        <SiteLink
          visible={false}
          url="/commissions"
          top={top}
        >
          <LinkTypography>Commissions</LinkTypography>
        </SiteLink>
        <SiteLink
          visible={false}
          url="/about"
          top={top}
        >
          <LinkTypography>About</LinkTypography>
        </SiteLink>
      </>
    );
  }

  return (
    <PaperRoot elevation={6}>
      
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: "100%"
        }}
      >

        <Grid
          container
          justifyContent="flex-start"
          item
          xs={6}
          sm={3}
        >
          <Logo
            sx={{
              paddingTop: "10px",
              paddingBottom: "10px"
            }}
          />
        </Grid>

        { desktopBreakpoint &&
          <Grid
            container
            justifyContent="center"
            item
            xs={6}
            sm={6}
          >
            <DesktopNavigationStack
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {navigationLinks(true)}
            </DesktopNavigationStack>
          </Grid>
        }

        <Grid
          container
          justifyContent="flex-end"
          item
          xs={6}
          sm={3}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
            sx={{
              paddingTop: "10px",
              paddingBottom: "10px"
            }}
          >
            <ExternalLinks
              fill="white"
              onClick={() => {
                window.location.href = "mailto:lottecolour@gmail.com";
              }}
            >
              <EmailRoundedIcon />
            </ExternalLinks>
            <ExternalLinks
              fill="#f77532"
              rel="noopener"
              onClick={() => {
                window.location.href = INSTAGRAM_URL;
              }}
            >
              <InstagramIcon />
            </ExternalLinks>
            <ExternalLinks
              fill="white"
              rel="noopener"
              onClick={() => {
                window.location.href = SHOP_URL;
              }}
            >
              <EtsyIcon />
            </ExternalLinks>
          </Stack>
        </Grid>

      </Grid>

      { mobileBreakpoint &&
        <MobileNavigationStack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {navigationLinks(false)}
        </MobileNavigationStack>
      }

    </PaperRoot>
  );
};

export default Nav;
