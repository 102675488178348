import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Link,
  LinkProps,
} from "@mui/material";

interface ExternalLinksProps extends LinkProps {
  children: React.ReactNode
  fill: string
}

const ExternalLinks = ({ children, fill, ...props }: ExternalLinksProps) => {

  const NavBarIconLink = styled(Link)<LinkProps>(({ theme }) => ({
    padding: "5px",
    margin: "5px",
    width: "24px",
    height: "24px",
    borderRadius: "20px",
    background: "#f77532",
    "& svg": {
      width: "24px",
      height: "24px",
      fill: fill,
      "&:hover": {
        cursor: "pointer",
      },
    },
  }));

  return (
    <NavBarIconLink {...props}>
      {children}
    </NavBarIconLink>
  )
};

export default ExternalLinks;